import React from "react";

const BrainingCampSection7 = (props) => {
    return(
        <section className="section-7">
            <div className="container">
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        alt={props.image1x?.altText}
                    />
                </div>
            </div>
        </section>
    )
}

export default BrainingCampSection7;